import React, {useEffect, useState} from 'react';
import axios from 'axios';


const ClusterList = () => {
    const [clusters, setClusters] = useState([]);
    const [showNotification, setShowNotification] = useState(false);
    const [showErrorNotification, setShowErrorNotification] = useState(false);

    useEffect(() => {
        fetchClusters()
        const call = setInterval(() => {
            fetchClusters()
        }, 60000);

        return () => clearInterval(call)
    }, []);

    const fetchClusters = () => {
        axios.get('/clusters/?status=Online')
            .then(response => {
                setClusters(response.data);
            })
            .catch(error => {
                console.log(error);
            });
    }

    const handleTriggerDestroy = (pr_number, branch_name) => {
        axios.post(`/trigger_github_destroy_action/`, {pr_number, branch_name})
            .then(response => {
                console.log(response.data);
                setShowNotification(true);
            })
            .catch(error => {
                console.log(error);
                setShowErrorNotification(true);
            });
    };

    // const handleInvoiceWorkflow = (jira_issue_key, cluster_id) => {
    //     axios.post(`/trigger_invoice_workflow/`, {jira_issue_key, cluster_id})
    //         .then(response => {
    //             console.log(response.data);
    //         })
    //         .catch(error => {
    //             console.log(error);
    //         });
    // };

    const handleCloseNotification = () => {
        setShowNotification(false);
    }
    const handleCloseErrorNotification = () => {
        setShowErrorNotification(false);
    }


    // const formatTime = (seconds) => {
    //     const days = Math.floor(seconds / 86400);
    //     const hours = Math.floor((seconds % 86400) / 3600);
    //     const minutes = Math.floor((seconds % 3600) / 60);
    //     const remainingSeconds = seconds % 60;
    //
    //     return `${days}d ${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${remainingSeconds.toString().padStart(2, '0')}`;
    // };


    return (
        <div style={{overflow: 'scroll', width: '100%', padding: '16px 0 0 16px'}}>
            {showNotification && (
                <div class="notification is-success">
                    <button class="delete" onClick={handleCloseNotification}></button>
                    Destroy workflow started!
                </div>
            )}
            {showErrorNotification && (
                <div class="notification is-danger">
                    <button class="delete" onClick={handleCloseErrorNotification}></button>
                    Failed to start workflow!
                </div>
            )}
            <table className={"table is-hoverable is-fullwidth"} style={{backgroundColor: "#F5FBEF"}}>
                <thead>
                <tr>
                    <th>Base Issue</th>
                    <th>Cluster Name</th>
                    <th>Duration</th>
                    <th>Invoice assigned</th>
                    <th>Dashboard assigned</th>
                    <th>PR Number</th>
                    <th>Link</th>
                    <th>Destroy env button</th>
                </tr>
                </thead>
                <tbody>
                {clusters.map(cluster => (
                    <tr key={cluster?.name}>
                        <td>{cluster.deployment?.jira_issue.issue_key}</td>
                        <td onClick={() => console.log("Beleka")}>{cluster?.name}</td>
                        <td>{cluster?.duration}</td>
                        {/*<td><a href={`https://github.com/coingate/coingate/tree/${cluster?.branch_name}`}*/}
                        {/*       target="_blank"*/}
                        {/*       rel="noreferrer noopener">{cluster?.branch_name}</a></td>*/}
                        <td>{cluster?.invoice_assignment?.issue_key}</td>
                        <td>{cluster?.dashboard_assignment?.issue_key}</td>
                        <td><a
                            href={`https://github.com/coingate/coingate/pull/${cluster?.deployment?.github_pr_number}`}
                            target="_blank"
                            rel="noreferrer noopener">{cluster?.deployment?.github_pr_number}</a>
                        </td>
                        <td>
                            <a href={`https://${cluster?.deployment?.github_pr_number}.dashboard.integration.coingate.dev`}
                               target="_blank" rel="noreferrer noopener">
                                {cluster?.deployment?.github_pr_number}.dashboard.integration.coingate.dev
                            </a>
                            <br/>
                            <a href={`https://${cluster?.deployment?.github_pr_number}.pay.integration.coingate.dev`}
                               target="_blank" rel="noreferrer noopener">
                                {cluster?.deployment?.github_pr_number}.pay.integration.coingate.dev
                            </a>
                            <br/>
                            <a href={`https://${cluster?.deployment?.github_pr_number}.admin.integration.coingate.dev`}
                               target="_blank" rel="noreferrer noopener">
                                {cluster?.deployment?.github_pr_number}.admin.integration.coingate.dev
                            </a>
                            <br/>
                            <a href={`https://${cluster?.deployment?.github_pr_number}.api.integration.coingate.dev`}
                               target="_blank" rel="noreferrer noopener">
                                {cluster?.deployment?.github_pr_number}.api.integration.coingate.dev
                            </a>
                            <br/>
                            <a href={`https://${cluster?.deployment?.github_pr_number}.account.integration.coingate.dev`}
                               target="_blank" rel="noreferrer noopener">
                                {cluster?.deployment?.github_pr_number}.account.integration.coingate.dev
                            </a>
                        </td>
                        <td>
                            <button
                                className="button is-danger"
                                onClick={() => {
                                    const confirmResult = window.confirm('Are you sure you want to trigger this?');
                                    if (confirmResult) {
                                        handleTriggerDestroy(cluster?.deployment?.github_pr_number, cluster?.deployment?.github_branch_name);
                                    }
                                }}
                            >
                                Destroy
                            </button>
                        </td>
                    </tr>
                ))}
                </tbody>
            </table>
        </div>
    );
};

export default React.memo(ClusterList);
