import React, {useEffect, useState} from 'react';
import axios from 'axios';
import {Link, withRouter} from 'react-router-dom';


const DeploymentList = ({history, location, match}) => {
    const [deployments, setDeployments] = useState([]);
    const [page, setPage] = useState(1);

    useEffect(() => {
        fetchDeployments(page)
        const call = setInterval(() => {
            fetchDeployments(page)
        }, 60000);

        return () => clearInterval(call)
    }, [page]);

    const fetchDeployments = (page) => {
        axios.get('/deployments/', {params: {page: page}})
            .then(response => {
                setDeployments(response.data.results);
            })
            .catch(error => {
                console.log(error);
            });
    }

    const handleCreateButtonClick = () => {
        // Add your logic for handling the button click here
        console.log('Create button clicked');
    };

    const sortedDeployments = deployments.sort((a, b) => b.id - a.id);


    return (
        <div>
            <table className={"table is-hoverable is-fullwidth"} style={{backgroundColor: "#F5FBEF"}}>
                <thead>
                <tr>
                    <th>Deployment ID</th>
                    <th>Jira issue Key</th>
                    <th>GitHub workflow run ID</th>
                </tr>
                </thead>
                <tbody>
                {deployments.map(deployment => (
                    <tr key={deployment?.id}>
                        <td onClick={() => console.log("Beleka")}>{deployment?.id}</td>
                        <td>{deployment?.jira_issue.issue_key}</td>

                        <td>
                            <a href={`https://github.com/coingate/coingate/actions/runs/${deployment?.github_workflow_run_id}`}
                               target="_blank" rel="noreferrer noopener">
                                {deployment?.github_workflow_run_id}
                            </a>
                        </td>
                    </tr>
                ))}
                </tbody>
            </table>
            <nav className="pagination is-right" role="navigation" aria-label="pagination">
                {page > 1 && (
                    <a className="pagination-previous" onClick={() => setPage(page - 1)}>
                        Previous
                    </a>
                )}
                <a className="pagination-next" onClick={() => setPage(page + 1)}>
                    Next page
                </a>
            </nav>

        </div>
    );
};

export default withRouter(React.memo(DeploymentList));
