import React from 'react';
import {withRouter, Link} from 'react-router-dom';

const Breadcrumbs = ({location}) => {
    const pathnames = location.pathname.split('/').filter((x) => x);

    return (
        <nav className="breadcrumb" aria-label="breadcrumbs">
            <ul>
                <li>
                    <Link to="/">Home</Link>
                </li>
                {pathnames.map((value, index) => {
                    const isLast = index === pathnames.length - 1;
                    const to = `/${pathnames.slice(0, index + 1).join('/')}`;

                    return isLast ? (
                        <li key={to} className="is-active">
                            <a href="#" aria-current="page">{value}</a>
                        </li>
                    ) : (
                        <li key={to}>
                            <Link to={to}>{value}</Link>
                        </li>
                    );
                })}
            </ul>
        </nav>

    );
};

export default withRouter(Breadcrumbs);
