import React, {useEffect, useState} from 'react';
import axios from 'axios';
import {Link, withRouter} from 'react-router-dom';


const AssignInvoice = ({history, location, match}) => {
    const [issues, setIssues] = useState([]);
    const [items, setItems] = useState(null);
    const [selectedCluster, setSelectedCluster] = useState(null);
    const [clusters, setClusters] = useState([]);
    const [showNotification, setShowNotification] = useState(false);
    const [showErrorNotification, setShowErrorNotification] = useState(false);
    const [disabled, setDisabled] = useState(true);
    const [errorMessage, setErrorMessage] = useState("");
    const projectKeys = ["B2B", "INV", "CHAIN", "CGC"];



    useEffect(() => {
        // Initialize an array to hold promises for each project's issues
        const issuePromises = projectKeys.map(projectKey => {
            return axios.get(`/jira-issues/?project=${projectKey}&active=true`).then(response => response.data).catch(error => console.log(error));
        });

        // Wait for all promises to resolve and then update the issues state
        Promise.all(issuePromises).then(issuesArrays => {
            // Flatten the array of arrays and set the issues state
            const allIssues = issuesArrays.flat(); // Use .flat() to combine arrays of issues into a single array
            allIssues.sort((a, b) => a.issue_key.localeCompare(b.issue_key));
            setIssues(allIssues);
        });

        // Fetch clusters as before
        axios.get('/clusters/?status=Online')
            .then(response => {
                setClusters(response.data);
            })
            .catch(error => {
                console.log(error);
            });

    }, []);


    const menuItems = issues.map(item => {
        return (
            <option value={item.issue_key}>{item.issue_key}</option>
        )
    });

    const clusterItems = clusters.map(cluster => {
        return (
            <option value={cluster.id}>{cluster.name}</option>
        )
    });


    const handleCreateButtonClick = () => {
        axios.post('/trigger_invoice_workflow/', {
            jira_issue_key: items,
            cluster_id: selectedCluster
        })
            .then(response => {
                if (response.data.success === true) {
                    setShowNotification(true);
                } else {
                    setErrorMessage(response.data.message);  // Set the error message
                    setShowErrorNotification(true);
                }
            })
            .catch(error => {
                // Handle network errors or other unexpected issues
                console.error(error);
                setErrorMessage("An unexpected error occurred."); // Set a default error message for unexpected issues
                setShowErrorNotification(true);
            });
    }

    const handleCloseNotification = () => {
        setShowNotification(false);
    }
    const handleCloseErrorNotification = () => {
        setShowErrorNotification(false);
    }

    useEffect(() => {
        setDisabled(!!!items || !!!selectedCluster)
    }, [items, selectedCluster])

    return (
        <>
            Please select Invoice issue key and backend cluster to assign invoice to that cluster.
            <form>
                <select onChange={event => setItems(event.target.value)}>
                    <option value="" disabled selected hidden>Please Choose...</option>
                    {menuItems}
                </select>
                <select onChange={event => setSelectedCluster(event.target.value)}>
                    <option value="" disabled selected hidden>Please Choose...</option>
                    {clusterItems}
                </select>
            </form>
            <div style={{paddingBottom: '16px'}}>
                <button className="button is-primary" disabled={disabled} onClick={handleCreateButtonClick}>Create
                </button>
            </div>
            {showNotification && (
                <div class="notification is-success">
                    <button class="delete" onClick={handleCloseNotification}></button>
                    Invoice workflow started! Usually it takes about 3min to complete.
                </div>
            )}
            {showErrorNotification && (
                <div class="notification is-danger">
                    <button class="delete" onClick={handleCloseErrorNotification}></button>
                    {errorMessage} {/* Render the error message here */}
                </div>
            )}
        </>
    )

}


export default withRouter(React.memo(AssignInvoice));
