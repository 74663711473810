import React from 'react';
import {Link} from 'react-router-dom';
import logo from '../logo.png';
import "./menu.css";

const Menu = () => {

    return (
        <aside className="menu is-left asideMenu">
            <figure className="image is-128x128">
                <img src={logo} alt="Logo"></img>
            </figure>
            <ul className="menu-list menuItems" style={{width: '100%'}}>
                <li><Link to="/" className="has-text-white menuItem" style={{textAlign: 'center'}}>Dashboard</Link></li>
                <li><Link to="/jira-issues" className="has-text-white menuItem" style={{textAlign: 'center'}}>Jira
                    Issues</Link></li>
                <li><Link to="/deployments" className="has-text-white menuItem"
                          style={{textAlign: 'center'}}>Deployments</Link></li>
                {/*<li><Link to="/create" className="has-text-white menuItem" style={{textAlign: 'center'}}>Create</Link></li>*/}
                <li><Link to="/assign_invoice" className="has-text-white menuItem" style={{textAlign: 'center'}}>Assign
                    Invoice</Link></li>
                <li><Link to="/assign_dashboard" className="has-text-white menuItem" style={{textAlign: 'center'}}>Assign
                    Dashboard</Link></li>
            </ul>
        </aside>
    )
};

export default React.memo(Menu);