import React, {useEffect, useState} from 'react';
import axios from 'axios';
import {Link, withRouter} from 'react-router-dom';


const DeploymentCreate = ({history, location, match}) => {
    const [issues, setIssues] = useState([]);
    const [items, setItems] = useState();

    useEffect(() => {
        axios.get('/jira-issues/?project=B2B')
            .then(response => {
                setIssues(response.data);
            })
            .catch(error => {
                console.log(error);
            });

    }, []);

    console.log(issues)
    const menuItems = issues?.map(item => {
        return (
            <option onClick={(e) => setItems(e.target.value)} value={item?.id}>{item?.issue_key}</option>
        )
    })

    console.log(items)

    const handleCreateButtonClick = () => {
        axios.post('/deployments/', {
            jira_issue: items
        })
            .then(response => {
                console.log(response.data);
            })
            .catch(error => {
                console.log(error);
            });
    }

    return (
        <>
            <form>
                <select>
                    <option value="" disabled selected hidden>Please Choose...</option>
                    {menuItems}
                </select>
            </form>
            <div style={{paddingBottom: '16px'}}>
                <button className="button is-primary" disabled={!!!items} onClick={handleCreateButtonClick}>Create
                </button>
            </div>
        </>
    )

}


export default withRouter(React.memo(DeploymentCreate));
