import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';
import axios from 'axios';
import ClusterList from './components/ClusterList';
import Breadcrumbs from './components/Breadcrumbs';
import DeploymentCreate from './components/DeploymentCreate';

// import Header from "./components/Header";
import Menu from "./components/Menu";
import JiraIssueList from "./components/JiraIssueList";
import {BrowserRouter, Route, Switch} from 'react-router-dom';
import DeploymentList from "./components/DeploymentList";
import AssignInvoice from "./components/AssignInvoice";
import AssignDashboard from "./components/AssignDashboard";

let apiBaseUrl;

switch(window.location.hostname) {
    case 'localhost':
        apiBaseUrl = 'http://localhost:8000/api/v1';
        break;
    case 'dashboard.coingate.dev':
        apiBaseUrl = 'https://api.dashboard.coingate.dev/api/v1';
        break;
    default:
        apiBaseUrl = 'http://localhost:8000/api/v1';
}

axios.defaults.baseURL = apiBaseUrl;


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(

    <BrowserRouter>
        <div style={{display: 'flex', height: '100vh', width: '100vw'}}>
           <Menu/>
           <div style={{display: 'flex', flexDirection: 'column', overflow: 'scroll', width: '100%', padding: '16px 0 0 16px'}}>
               <Breadcrumbs />
               <Switch>
                    <Route exact path="/" component={ClusterList} />
                    <Route path="/jira-issues" component={JiraIssueList} />
                    <Route path="/deployments" component={DeploymentList} />
                    <Route path="/create" component={DeploymentCreate} />
                    <Route path="/assign_invoice" component={AssignInvoice} />
                    <Route path="/assign_dashboard" component={AssignDashboard} />
                </Switch>
           </div>
        </div>
    </BrowserRouter>,

);


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
